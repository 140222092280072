
    import moment from "moment";
    import {onUnmounted, ref, toRaw, nextTick} from "vue";
    import * as echarts from "echarts";
    import {getSearch} from '@/network/customer';
    import {message} from "ant-design-vue";

    interface WhereItem {
        type: undefined | number;
        time: any;
    }

    type EChartsOption = echarts.EChartsOption;
    export default {
        name: "Canalyze",
        setup() {
            const where = ref<WhereItem>({
                type: undefined,
                time: [],
            });
            let range = ref<any>({
                'startTime': '',
                'endTime': '',
            });
            const avg = ref<any>('');
            const max = ref<any>({
                deliver_pay: '',
                complain_pay: '',
                complain_deliver: '',
            });
            const is_show = ref(false);
            const show_chart = ref(false);
            const pie = async () => {
                await getSearch({...where.value}).then((response) => {
                    //console.log(response);
                    if (where.value.type == 1) {
                        is_show.value = false;
                        show_chart.value = true;
                        option = {
                            title: {
                                text: '每个站接受补偿数量占比',
                                subtext: '',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    // Use axis to trigger tooltip
                                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                }
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left'
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                //gridIndex:0,
                                axisLabel: {interval: 0, rotate: 38},
                                data: response.res.sites.name,
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: [
                                {
                                    name: '不接受补偿',
                                    type: 'bar',
                                    stack: 'total',
                                    label: {
                                        show: true
                                    },
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    data: response.res.sites.noaccept
                                },
                                {
                                    name: '接受补偿',
                                    type: 'bar',
                                    stack: 'total',
                                    label: {
                                        show: true
                                    },
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    data: response.res.sites.accept
                                }
                            ]
                        };
                        option1 = {
                            title: {
                                text: '接受补偿总数量占比',
                                subtext: '',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left'
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: '50%',
                                    data: response.res.comments,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        };
                        option2 = {
                            title: {
                                text: '各物流商占比',
                                subtext: '',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left'
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: '50%',
                                    data: response.res.shippers,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        };
                    } else if (where.value.type == 2) {
                        is_show.value = false;
                        show_chart.value = false;
                        option0 = {
                            title: {
                                text: '已签收数量占比',
                                subtext: '',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left'
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: '50%',
                                    data: response.res.already,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        };
                        option1 = {
                            title: {
                                text: '已重派数量占比',
                                subtext: '',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left'
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: '50%',
                                    data: response.res.comments,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        };
                        option2 = {
                            title: {
                                text: '各物流商占比',
                                subtext: '',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left'
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: '50%',
                                    data: response.res.shippers,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        };
                    } else if (where.value.type == 3) {
                        is_show.value = true;
                        show_chart.value = false;
                        avg.value = response.res.avg;
                        max.value.deliver_pay = response.res.max.deliver_pay[0].deliver_pay;
                        max.value.complain_pay = response.res.max.complain_pay[0].complain_pay;
                        max.value.complain_deliver = response.res.max.complain_deliver[0].complain_deliver;
                        option0 = {
                            title: {
                                text: '各站数量占比',
                                subtext: '',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left'
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: '50%',
                                    data: response.res.sites,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        };
                        option1 = {
                            title: {
                                text: '各国家数量占比',
                                subtext: '',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left'
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: '50%',
                                    data: response.res.countrys,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        };
                        option2 = {
                            title: {
                                text: '各原因占比',
                                subtext: '',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left'
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: '50%',
                                    data: response.res.reasons,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        };
                    }
                })
                initChart(where.value.type);
            };
            const search = () => {
                console.log(where.value);
                if (where.value.type && where.value.time.length == 2) {
                    pie();
                } else {
                    message.error('筛选条件漏选！');
                }
            };
            const dateFormat = "YYYY-MM-DD";
            const handleTimeChange = (value, dateString) => {
                console.log('Formatted Selected Time: ', dateString);
                range.value.startTime = dateString[0] + " 0:0:0";
                range.value.endTime = dateString[1] + " 23:59:59";
                //console.log(range.value);
                where.value.time = (dateString[0] && dateString[1]) ? [range.value.startTime, range.value.endTime] : [];
            };
            let echart = echarts;
            let option: EChartsOption = {};
            let option0: EChartsOption = {};
            let option1: EChartsOption = {};
            let option2: EChartsOption = {};

            function initChart(type) {
                //console.log(11111);
                let chart = echart.init(document.getElementById("myEcharts")!);
                let chart0 = echart.init(document.getElementById("myEcharts0")!);
                let chart1 = echart.init(document.getElementById("myEcharts1")!);
                let chart2 = echart.init(document.getElementById("myEcharts2")!);
                if(type==2||type==3){
                    chart.dispose();
                }else if(type==1){
                    chart0.dispose();
                }
                // 把配置和数据放这里
                chart.setOption(option);
                chart0.setOption(option0);
                chart1.setOption(option1);
                chart2.setOption(option2);
                window.onresize = function () {
                    //自适应大小
                    chart.resize();
                    chart0.resize();
                    chart1.resize();
                    chart2.resize();
                };

            }

            onUnmounted(() => {
                echart.dispose;
            });
            return {
                handleTimeChange,
                search,
                where,
                avg,
                max,
                is_show,
                show_chart
            };
        }
    }
